<template>
    <div class="animated fadeIn">
    <div id="snackbar"></div>    
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>States </strong> <small>Form</small>
          </div>
          <b-form v-on:submit.prevent.self="submit">
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="country">Country <span class="text-danger">*</span></label>
               <b-form-select @change.native="getStateList($event.target.value)" required 
                    v-model="state.CountryID" id="month1"
                  :plain="true"
                   value-field="CountryID"
                  text-field="CountryName"
                  :options= countries>
                  <template slot="first">
                    <option :value="0" disabled>-- Select Country --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="title">Title <span class="text-danger">*</span></label>
                <b-form-input type="text" required v-model="state.StateName" id="id" placeholder="State Name"></b-form-input>
              </b-form-group>
            </b-col>
            
          </b-row>
          

          <b-row>
            
            <b-col sm="6">
              <b-form-group>
                <label for="country">Status</label>
                <br>
                  <c-switch class="mx-1" color="primary" v-model="state.StateIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />             
              </b-form-group>
            </b-col>
          </b-row>


          <b-row>
             <b-col sm="6">
                  <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> {{ commontext.submitButton }}</b-button>
              </b-col>

              <b-col sm="6">
                  <b-button @click="resetForm" size="sm" variant="success"><i class="fa fa-dot-circle-o"></i> Reset </b-button>
              </b-col>
          </b-row>
          </b-form>
        </b-card>

         <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of States
        <div class="card-header-actions">
          
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
         <div slot="StateID" slot-scope="props">
            <i class="fa fa-edit" @click="editState(props.row.StateID)"></i>
          </div>
          <div slot="StateIsActive" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.StateIsActive" variant="3d" label v-bind="labelIcon" @change.native="changeStatus(props.row.StateID)"/>             
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>

      </b-col>
    </b-row>
    <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
         <div class="d-block text-center">
           <div>{{ modalObject.message }}</div>
         </div>
       </b-modal>
    </div>
</template>
<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import { ClientTable, Event } from "vue-tables-2";
Vue.use(ClientTable);
export default {
  name: "States",
  components: {
    cSwitch,
    ClientTable,
    Event
  },
  data() {
    return {
      commontext: {
        submitButton: "Submit"
      },
      state: {
        Flag: 1,
        StateID: null,
        StateName: "",
        CountryID: 0,
        StateIsActive: true
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      countries: [],
      data: [],
      columns: ["StateName", "StateIsActive", "StateID"],

      options: {
        headings: {
          name: "State Name",
          StateID: "Edit",
          StateIsActive: "Status"
        },
        sortable: ["StateName"],
        filterable: ["StateName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    getCountries: function() {
      // let res = await MasterService.getCountriesDDL();
      MasterService.getCountriesDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.countries = data.CountryList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm: function() {
      this.data = [];
      this.commontext.submitButton = "Submit";
      this.state = {
        StateID: null,
        Flag: 1,
        StateName: "",
        CountryID: 0,
        StateIsActive: true
      };
    },
    getStateList: function(CountryID) {
      // let res = await MasterService.getStates(e);
      let payload = {
        CountryID
      };
      this.state.StateName = "";
      MasterService.getStates(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.StateList.length > 0) {
            this.states = data.StateList.reverse();
            this.data = this.states;
          } else {
            data.Message ? this.showMessageSnackbar(data.Message) : "";
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.state.CountryID == 0) {
        this.showMessageSnackbar("Select a Country");
        return false;
      } else if (this.state.StateName == 0) {
        this.showMessageSnackbar("State Name Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");
      MasterService.addState(this.state)
        .then(response => {
          const { data } = response;
          // this.getStateList(this.state.CountryID);
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.resetForm();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editState: function(StateID) {
      let payload = {
        StateID
      };

      MasterService.getStateByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.StateList.length > 0) {
            this.commontext.submitButton = "Update State";
            this.state = data.StateList[0];
            this.state.Flag = 2;
            this.scrollToTop();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    changeStatus: function(ID) {
      let payload = {
        TableName: "StateMaster",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
